$(function () {
  var hideBackgroundModal = function () {
    $('#backgroundModal').fadeOut();
    $('#backgroundModal').click(function () {
      $($(this).fadeOut(300));
    });
  }
  hideBackgroundModal();

  //header-modal
  var headerModal = $('.header__modal');
  var headerHumberger = $('#headerHumberger');
  var headerSearch = $('#headerSearch');
  var headerShare = $('#headerShare');
  var headerSlideHumberger = $('.slideModal__humberger');
  var headerSlideShare = $('.slideModal__share');
  var headerSlideSearch = $('.slideModal__search');
  headerModal.fadeOut();
  const closeHeaderModal = function () {
    headerSlideHumberger.removeClass('slideModal__slide--active');
    headerSlideSearch.removeClass('slideModal__slide--active');
    headerSlideShare.removeClass('slideModal__slide--active');
    $(this).fadeOut(500);
  }
  const toggleHumbergerModal = function () {
    if (headerSlideHumberger.hasClass('slideModal__slide--active')) {
      headerSlideHumberger.removeClass('slideModal__slide--active');
    } else {
      headerModal.fadeIn();
      headerSlideHumberger.addClass('slideModal__slide--active');
      headerSlideSearch.removeClass('slideModal__slide--active');
      headerSlideShare.removeClass('slideModal__slide--active');
    }
  }
  const toggleSearchModal = function () {
    if (headerSlideSearch.hasClass('slideModal__slide--active')) {
      headerSlideSearch.removeClass('slideModal__slide--active');
    } else {
      headerModal.fadeIn();
      headerSlideHumberger.removeClass('slideModal__slide--active');
      headerSlideSearch.addClass('slideModal__slide--active');
      headerSlideShare.removeClass('slideModal__slide--active');
    }
  }
  const toggleShareModal = function () {
    if (headerShare.hasClass('slideModal__slide--active')) {
      headerShare.removeClass('slideModal__slide--active');
    } else {
      headerModal.fadeIn();
      headerSlideHumberger.removeClass('slideModal__slide--active');
      headerSlideSearch.removeClass('slideModal__slide--active');
      headerSlideShare.addClass('slideModal__slide--active');
    }
  }
  //headerModal
  headerModal.click(function () {
    closeHeaderModal.call(headerModal);
  });
  //humberger
  headerHumberger.click(function () {
    toggleHumbergerModal.call(headerHumberger);
  });
  //search
  headerSearch.click(function () {
    toggleSearchModal.call(headerSearch);
  });
  //share
  headerShare.click(function () {
    toggleShareModal.call(headerShare);
  });
  //headerの下線を表示
  var prePathName = location.pathname;
  var pathName = prePathName.split('/')[1];

  var headerMenu = $('.header__menu');
  var i = 0;

  while (i < headerMenu.length) {
    const menuName = $(headerMenu[i]).find('.header__menuText').html();
    if (pathName === menuName.toLowerCase()) {
      $(headerMenu[i]).addClass('header__menu--selected');
    }
    i++
  }
})
